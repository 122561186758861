import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import '../new/new.scss'
import {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import {storage} from "../../firebase";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import {TagsInput} from "react-tag-input-component";
import {Box, CircularProgress, Stack} from "@mui/material";
import {Editor} from "@tinymce/tinymce-react";

const Edit = ({object, title}) => {
    const navigate = useNavigate();
    const {id} = useParams();
    if (!id) {
        navigate('/');
    }

    const [inputs, setInputs] = useState([]);
    const [file, setFile] = useState("");
    const [per, setPerc] = useState(null);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getFormData = async () => {
            let editData = await object.getById(id);
            if (editData) {
                setInputs(editData);
            }
            setLoading(false);
        };

        getFormData();
    }, [id])

    useEffect(() => {
        const uploadFile = () => {
            const fileName = new Date().getTime() + file.name.replace(/ /g, '').toLowerCase();
            const storageRef = ref(storage, fileName);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log("Upload is " + progress + "% done");
                    setPerc(progress);
                    switch (snapshot.state) {
                        case "paused":
                            console.log("Upload is paused");
                            break;
                        case "running":
                            console.log("Upload is running");
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    console.log(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        setData((prev) => ({...prev, img: downloadURL}));
                    });
                }
            );
        };
        file && uploadFile();
    }, [file]);

    const handleEditor = (editorBody, editor) => {
        console.log(editor);console.log(editorBody);
        setData((prev) => ({...prev, [editor.id]: editorBody}));
    };

    const handleTagInput = (content) => {
        setData((prev) => ({...prev, tags: content}));
    };
    const handleInput = (e) => {
        const id = e.target.id;
        const value = e.target.value;

        setData({...data, [id]: value});
    };

    const editorRef = useRef(null);

    const handleSave = async (e) => {
        e.preventDefault();
        console.log(data);
        object.updateRecord(id, data);
        navigate(-1);
    }

    return (
        <>
            {loading &&
                <Stack className="loader" alignItems="center" spacing={5}>
                    <CircularProgress/>
                </Stack>
            }
            <div className={loading ? 'new loading' : 'new'}>
                <Sidebar/>
                <div className="newContainer">
                    <Navbar/>
                    <div className="top">
                        <h1>{title}</h1>
                    </div>
                    <div className="bottom">
                        <div className="left">
                            <img
                                src={
                                    file
                                        ? URL.createObjectURL(file)
                                        : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                                }
                                alt=""
                            />
                        </div>
                        <div className="right">
                            <form onSubmit={handleSave}>
                                <div className="formInput">
                                    <label htmlFor="file">
                                        Image: <DriveFolderUploadOutlinedIcon className="icon"/>
                                    </label>
                                    <input
                                        type="file"
                                        id="file"
                                        onChange={(e) => setFile(e.target.files[0])}
                                        style={{display: "none"}}
                                    />
                                </div>

                                {inputs.length > 0 && inputs.map((input) => (
                                    <div key={input.id}
                                         className={input.source == 'editor' ? 'full-width' : 'formInput'}>
                                        {input.source == 'input' &&
                                            <div key={input.id}>
                                                <label>{input.label}</label>
                                                <input id={input.id} type={input.type} placeholder={input.placeholder}
                                                       onChange={handleInput} defaultValue={input.value}/>
                                            </div>
                                        }
                                        {input.source == 'textbox' &&
                                            <div key={input.id}>
                                                <label>{input.label}</label>
                                                <textarea id={input.id} placeholder={input.placeholder}
                                                          onChange={handleInput} defaultValue={input.value}></textarea>
                                            </div>
                                        }
                                        {input.source == 'select' &&
                                            <div>
                                                <label>{input.label}</label>
                                                <select id={input.id} onChange={handleInput} defaultValue={input.value}>
                                                    {input.hasOwnProperty('options') && input.options.map(option =>
                                                        <option key={option.key}
                                                                value={option.key}>{option.value}</option>
                                                    )}
                                                </select>
                                            </div>
                                        }
                                        {input.source == 'chip' &&
                                            <div>
                                                <label>{input.label}</label>
                                                <TagsInput
                                                    id={input.id}
                                                    value={input.value}
                                                    onChange={handleTagInput}
                                                    name={input.id}
                                                    placeHolder="enter topics"
                                                />
                                            </div>
                                        }
                                        {input.source == 'editor' &&
                                            <div className="full-width">
                                                <label>{input.label}</label>
                                                <>
                                                    <Editor
                                                        id={input.id}
                                                        apiKey={process.env.TinyCloud_API_Key}
                                                        onInit={(evt, editor) => editorRef.current = editor}
                                                        initialValue={input.value}
                                                        init={{
                                                            height: 500,
                                                            menubar: false,
                                                            plugins: [
                                                                'codesample'
                                                            ],
                                                            codesample_languages: [
                                                                {text: 'Python', value: 'python'},
                                                                {text: 'Java', value: 'java'},
                                                                {text: 'Ruby', value: 'ruby'},
                                                                {text: 'PHP', value: 'php'},
                                                                {text: 'JavaScript', value: 'javascript'},
                                                                {text: 'HTML/XML', value: 'markup'},
                                                                {text: 'CSS', value: 'css'},
                                                                {text: 'C', value: 'c'},
                                                                {text: 'C#', value: 'csharp'},
                                                                {text: 'C++', value: 'cpp'}
                                                            ],
                                                            toolbar: 'codesample | formatselect | ' +
                                                                'bold italic backcolor | alignleft aligncenter ' +
                                                                'alignright alignjustify | bullist numlist outdent indent',
                                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                        }}
                                                        onEditorChange={handleEditor}
                                                    />
                                                </>
                                            </div>
                                        }
                                    </div>
                                ))}

                                <div className="full-width">
                                    <button disabled={per !== null && per < 100} type="submit"
                                            className="submit-button">Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Edit;