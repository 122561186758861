import {collection, doc, getDoc, getDocs, query, updateDoc, where} from "firebase/firestore";
import {db} from "../firebase";
import {useContext} from "react";
import {AuthContext} from "../context/AuthContext";

export class ParentConfig {
    dbName = '';
    config = {
        title: 'Wezual Page',
        headers: {}
    };

    currentUser = useContext(AuthContext);

    getTitle() {
        return this.config.title;
    }

    getHeaders() {
        return this.config.headers;
    }

    getInputs() {
        return this.config.inputs;
    }

    getConfig() {
        return this.config;
    }

    async getCategoryForDropDown() {
        const q = query(collection(db, "categories"), where("status", "==", "1"));

        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => {
            return {
                key: doc.id,
                value: doc.data().title
            }
        });
    }

    filterDocumentForConfig(docSnap) {
        if (docSnap.exists()) {
            const document = docSnap.data();
            this.config.inputs.forEach(input => {
                if (document.hasOwnProperty(input.id)) {
                    input['value'] = document[input.id];
                } else {
                    console.log(`no data`, input.id);
                }
            });

            return this.config.inputs;
        } else {
            console.log("No such document!");
            return false;
        }
    }

    async updateRecord(id, data) {
        if(Object.keys(data).length > 0) {
            const docRef = doc(db, this.dbName, id);
            await updateDoc(docRef, data);
        }
    }

    async getById(id) {
        const docRef = doc(db, this.dbName, id);
        const docSnap = await getDoc(docRef);

        return this.filterDocumentForConfig(docSnap);
    }
}