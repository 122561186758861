import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import "./style/dark.scss";
import {useContext} from "react";
import {DarkModeContext} from "./context/darkModeContext";
import {AuthContext} from "./context/AuthContext";
import {CategoryConfig} from "./page-configs/CategoryConfig";
import {UserConfig} from "./page-configs/UserConfig";
import {ArticleConfig} from "./page-configs/ArticleConfig";
import Edit from "./pages/edit/Edit";

function App() {
    const {darkMode} = useContext(DarkModeContext);

    const {currentUser} = useContext(AuthContext)

    const RequireAuth = ({children}) => {
        return currentUser ? children : <Navigate to="/login"/>;
    };

    return (
        <div className={darkMode ? "app dark" : "app"}>
            <BrowserRouter>
                <Routes>
                    <Route path="/">
                        <Route path="login" element={<Login/>}/>
                        <Route
                            index
                            element={
                                <RequireAuth>
                                    <Home/>
                                </RequireAuth>
                            }
                        />
                        <Route path="users">
                            <Route
                                index
                                element={
                                    <RequireAuth>
                                        <List object={new UserConfig()}/>
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="edit/:id"
                                element={
                                    <RequireAuth>
                                        <Edit object={new UserConfig()} title="Edit Article"/>
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path=":userId"
                                element={
                                    <RequireAuth>
                                        <Single/>
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="new"
                                element={
                                    <RequireAuth>
                                        <New object={new UserConfig()} title="Add New User"/>
                                    </RequireAuth>
                                }
                            />
                        </Route>
                        <Route path="articles">
                            <Route
                                index
                                element={
                                    <RequireAuth>
                                        <List object={new ArticleConfig()}/>
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path=":articleId"
                                element={
                                    <RequireAuth>
                                        <Single/>
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="edit/:id"
                                element={
                                    <RequireAuth>
                                        <Edit object={new ArticleConfig()} title="Edit Article"/>
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="new"
                                element={
                                    <RequireAuth>
                                        <New object={new ArticleConfig()} title="Add New Article"/>
                                    </RequireAuth>
                                }
                            />
                        </Route>
                        <Route path="categories">
                            <Route
                                index
                                element={
                                    <RequireAuth>
                                        <List object={new CategoryConfig()}/>
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path=":categoryId"
                                element={
                                    <RequireAuth>
                                        <Single/>
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="edit/:id"
                                element={
                                    <RequireAuth>
                                        <Edit object={new CategoryConfig()} title="Edit Category"/>
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="new"
                                element={
                                    <RequireAuth>
                                        <New object={new CategoryConfig()} title="Add New Category"/>
                                    </RequireAuth>
                                }
                            />
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;