// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";
import {getAnalytics} from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDgOq-S86-4y0fjBBLUI9035CDz4Uwl1WQ",
    authDomain: "wezual-code.firebaseapp.com",
    projectId: "wezual-code",
    storageBucket: "wezual-code.appspot.com",
    messagingSenderId: "918731520697",
    appId: "1:918731520697:web:a2c0e9549f465aefa8f968",
    measurementId: "G-QEREEW9JBG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);

const analytics = getAnalytics(app);