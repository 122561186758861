import {ParentConfig} from './ParentConfig'
import {Link} from "react-router-dom";
import {createUserWithEmailAndPassword} from "firebase/auth";
import {auth, db} from "../firebase";
import {doc, getDoc, serverTimestamp, setDoc, updateDoc} from "firebase/firestore";

export class UserConfig extends ParentConfig {
    dbName = 'users';
    config = {
        title: 'Users',
        dbSource: this.dbName,
        addNew: {
            title: 'Add User',
            link: '/users/new'
        },
        headers: [
            {
                field: "user",
                headerName: "User",
                width: 230,
                renderCell: (params) => {
                    return (
                        <div className="cellWithImg">
                            <img className="cellImg" src={params.row.img} alt="avatar"/>
                            {params.row.username}
                        </div>
                    );
                },
            },
            {
                field: "displayName",
                headerName: "Name",
                width: 230,
            },
            {
                field: "email",
                headerName: "Email",
                width: 230,
            },
            {
                field: "status",
                headerName: "Status",
                width: 160,
                renderCell: (params) => {
                    return (
                        <div className={`cellWithStatus ${params.row.status ? 'active' : 'pending'}`}>
                            {params.row.status == 1 ? 'Enable' : 'Disable'}
                        </div>
                    );
                },
            },
            {
                field: "timestamp", headerName: "Created At",
                width: 200,
                renderCell: (params) => {
                    return (
                        params.row.timestamp.toDate().toDateString()
                    );
                },
            },
            {
                field: "action",
                headerName: "Action",
                width: 200,
                renderCell: (params) => {
                    let link = "/users/" + params.row.id;
                    let editLink = "/users/edit/"+params.row.id;
                    return (
                        <div className="cellAction">
                            <Link to={link} style={{textDecoration: "none"}}>
                                <div className="viewButton">View</div>
                            </Link>
                            <Link to={editLink} style={{ textDecoration: "none" }}>
                                <div className="editButton">Edit</div>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        inputs: [
            {
                id: "username",
                label: "Username",
                source: 'input',
                type: "text",
                placeholder: "john_doe",
            },
            {
                id: "displayName",
                label: "Name and surname",
                source: 'input',
                type: "text",
                placeholder: "John Doe",
            },
            {
                id: "email",
                label: "Email",
                source: 'input',
                type: "mail",
                placeholder: "john_doe@gmail.com",
            },
            {
                id: "phone",
                label: "Phone",
                source: 'input',
                type: "text",
                placeholder: "+1 234 567 89",
            },
            {
                id: "password",
                label: "Password",
                source: 'input',
                type: "password",
            },
            {
                id: 'status',
                label: "Status",
                source: 'select',
                type: "text",
                options: [
                    {key: '', value: 'Select Status'},
                    {key: 0, value: 'Disable'},
                    {key: 1, value: 'Enable'},
                ]
            }
        ]
    }

    constructor(props) {
        super(props);
    }

    async onSubmit(data) {
        try {
            const res = await createUserWithEmailAndPassword(auth, data.email, data.password);
            await setDoc(doc(db, this.dbName, res.user.uid), {
                ...data,
                timestamp: serverTimestamp()
            });

        } catch (err) {
            console.log(err);
        }
    }
}