import {ParentConfig} from "./ParentConfig";
import {doc, getDoc, serverTimestamp, setDoc, updateDoc} from "firebase/firestore";
import {db} from "../firebase";
import {Link} from "react-router-dom";

export class ArticleConfig extends ParentConfig {
    dbName = 'articles';
    config = {
        title: 'Articles',
        dbSource: this.dbName,
        addNew: {
            title: 'Add Article',
            link: '/articles/new'
        },
        headers: [
            {field: "title", headerName: "Title", width: 400},
            {field: "shortDescription", headerName: "Short Description", width: 250},
            {
                field: "status",
                headerName: "Status",
                width: 80,
                renderCell: (params) => {
                    return (
                        <div className={`cellWithStatus ${params.row.status ? 'active' : 'pending'}`}>
                            {params.row.status == 1 ? 'Enable' : 'Disable'}
                        </div>
                    );
                },
            },
            {
                field: "timestamp", headerName: "Created At",
                width: 140,
                renderCell: (params) => {
                    return (
                        params.row.timestamp.toDate().toDateString()
                    );
                },
            },
            {
                field: "action",
                headerName: "Action",
                width: 140,
                renderCell: (params) => {
                    let link = "/articles/" + params.row.id;
                    let editLink = "/articles/edit/" + params.row.id;
                    return (
                        <div className="cellAction">
                            <Link to={link} style={{textDecoration: "none"}}>
                                <div className="viewButton">View</div>
                            </Link>
                            <Link to={editLink} style={{textDecoration: "none"}}>
                                <div className="editButton">Edit</div>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        inputs: [
            {
                id: 'title',
                label: "Title",
                source: 'input',
                type: "text",
                placeholder: "Article Title",
                required: true
            },
            {
                id: 'shortDescription',
                label: "Short Description (SEO)",
                source: 'textbox',
                type: "text",
                placeholder: "Short Description (SEO)",
            },
            {
                id: 'status',
                label: "Status",
                source: 'select',
                type: "text",
                options: [
                    {key: '', value: 'Select Status'},
                    {key: 0, value: 'Disable'},
                    {key: 1, value: 'Enable'},
                ]
            },
            {
                id: 'categoryId',
                label: "Category",
                source: 'select',
                type: "text",
                options: [
                    {key: '', value: 'Select Category'},
                ]
            },
            {
                id: 'tags',
                label: "Topics",
                source: 'chip',
                type: "text",
            },
            {
                id: 'content',
                label: "Content",
                source: 'editor',
                type: "text",
                placeholder: "Content...",
            },
        ]
    }

    constructor(props) {
        super(props);
        this.getCategoryForDropDown().then(res => {
            this.config.inputs.forEach((input, key) => {
                if (input.id == 'categoryId') {
                    this.config.inputs[key].options.push(...res)
                }
            });
        });

    }

    async onSubmit(data) {
        try {
            const id = data.title.toLowerCase().replace(/^\s+|\s+$/gm,'').replace(/\s/g, "-").replace(/[^a-zA-Z0-9-]/g, "");
            const tags = data.tags;
            let tagList = {};
            tags.forEach(tag => {
                let name = tag.toLowerCase().replace(/\s/g, "-").replace(/[^a-zA-Z0-9-]/g, "")
                tagList[name] = tag;
            })
            delete data.tags;
            await setDoc(doc(db, this.dbName, id), {
                ...data,
                timestamp: serverTimestamp(),
                authorId: this.currentUser.currentUser.uid,
                topics: tagList
            });
        } catch (err) {
            console.log(err);
        }
    }

    async getById(id) {
        const docRef = doc(db, this.dbName, id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const document = docSnap.data();
            this.config.inputs.forEach(input => {
                if (document.hasOwnProperty(input.id) && input.id != "tags") {
                    input['value'] = document[input.id];
                } else if (input.id == "tags") {
                    const tags = Object.values(document.topics);
                    input['value'] = tags;
                } else {
                    console.log(`no data`, input.id);
                }
            });

            return this.config.inputs;
        } else {
            console.log("No such document!");
            return false;
        }
    }

    async updateRecord(id, data) {
        if (Object.keys(data).length > 0) {
            const docRef = doc(db, this.dbName, id);
            if (data.hasOwnProperty('tags')) {
                const tags = data.tags;
                let tagList = {};
                tags.forEach(tag => {
                    let name = tag.toLowerCase().replace(/\s/g, "-").replace(/[^a-zA-Z0-9-]/g, "")
                    tagList[name] = tag;
                })
                delete data.tags;
                data['topics'] = tagList;
            }
            await updateDoc(docRef, data);
        }
    }
}