import {ParentConfig} from "./ParentConfig";
import {doc, serverTimestamp, setDoc, getDoc, updateDoc} from "firebase/firestore";
import {db} from "../firebase";
import {Link} from "react-router-dom";

export class CategoryConfig extends ParentConfig {
    dbName = 'categories';
    config = {
        title: 'Categories',
        dbSource: this.dbName,
        addNew: {
            title: 'Add Category',
            link: '/categories/new'
        },
        headers: [
            {field: "title", headerName: "Title", width: 250},
            {field: "content", headerName: "Content", width: 350},
            {
                field: "status",
                headerName: "Status",
                width: 160,
                renderCell: (params) => {
                    return (
                        <div className={`cellWithStatus ${params.row.status ? 'active' : 'pending'}`}>
                            {params.row.status == 1 ? 'Enable' : 'Disable'}
                        </div>
                    );
                },
            },
            {
                field: "timestamp", headerName: "Created At",
                width: 200,
                renderCell: (params) => {
                    return (
                        params.row.timestamp.toDate().toDateString()
                    );
                },
            },
            {
                field: "action",
                headerName: "Action",
                width: 200,
                renderCell: (params) => {
                    let link = "/categories/" + params.row.id;
                    let editLink = "/categories/edit/" + params.row.id;
                    return (
                        <div className="cellAction">
                            <Link to={link} style={{textDecoration: "none"}}>
                                <div className="viewButton">View</div>
                            </Link>
                            <Link to={editLink} style={{textDecoration: "none"}}>
                                <div className="editButton">Edit</div>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        inputs: [
            {
                id: 'title',
                label: "Title",
                source: 'input',
                type: "text",
                placeholder: "Category Title",
                required: true
            },
            {
                id: 'content',
                label: "Content",
                source: 'editor',
                type: "text",
                placeholder: "Content...",
            },
            {
                id: 'shortDescription',
                label: "Short Description (SEO)",
                source: 'textbox',
                type: "text",
                placeholder: "Short Description (SEO)",
            },
            {
                id: 'status',
                label: "Status",
                source: 'select',
                type: "text",
                options: [
                    {key: '', value: 'Select Status'},
                    {key: 0, value: 'Disable'},
                    {key: 1, value: 'Enable'},
                ]
            }
        ]
    }

    constructor(props) {
        super(props);
    }

    async onSubmit(data) {
        try {
            const id = data.title.toLowerCase().replace(/\s/g, "-").replace(/[^a-zA-Z0-9-]/g, "")
            await setDoc(doc(db, this.dbName, id), {
                ...data,
                timestamp: serverTimestamp()
            });
        } catch (err) {
            console.log(err);
        }
    }
}