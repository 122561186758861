import "./datatable.scss";
import {DataGrid} from "@mui/x-data-grid";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    collection,
    getDocs,
    deleteDoc,
    doc,
    onSnapshot,
} from "firebase/firestore";
import {db} from "../../firebase";


const Datatable = ({object}) => {
    const navigate = useNavigate();
    if (!object) {
        navigate('/');
    }
    let config = object.config;

    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            let list = [];
            try {
                const querySnapshot = await getDocs(collection(db, config.dbSource));
                querySnapshot.forEach((doc) => {
                    list.push({id: doc.id, ...doc.data()});
                });
                setData(list);
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();

        // LISTEN (REALTIME)
        // const unsub = onSnapshot(
        //     collection(db, "users"),
        //     (snapShot) => {
        //         let list = [];
        //         snapShot.docs.forEach((doc) => {
        //             list.push({ id: doc.id, ...doc.data() });
        //         });
        //         setData(list);
        //     },
        //     (error) => {
        //         console.log(error);
        //     }
        // );
        //
        // return () => {
        //     unsub();
        // };
    }, [config]);

    const handleDelete = async (id) => {
        try {
            await deleteDoc(doc(db, config.dbSource, id));
            setData(data.filter((item) => item.id !== id));
        } catch (err) {
            console.log(err);
        }
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                let link = "/users/" + params.row.id;
                return (
                    <div className="cellAction">
                        <Link to={link} style={{textDecoration: "none"}}>
                            <div className="viewButton">View</div>
                        </Link>
                        <div
                            className="deleteButton"
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];
    return (
        <div className="datatable">
            <div className="datatableTitle">
                {config.title}
                {config.hasOwnProperty('addNew') &&
                    <Link to={config.addNew.link} className="link">
                        {config.addNew.title}
                    </Link>
                }
            </div>
            <DataGrid
                className="datagrid"
                rows={data}
                columns={config.headers}
                pageSize={9}
                rowsPerPageOptions={[9]}
            />
        </div>
    );
};

export default Datatable;
